#root {
	/* background: url('./bgImg/bg.png') #333 !important; */
	/* balázs szerint nem jó¨: */
	/* background-color: #282c34 !important; */
	/* background-color: #22272e !important; */
	/* background: rgb(4,13,33); */
	/* background: linear-gradient(156deg, rgba(4,13,33,1) 27%, rgba(3,30,73,1) 76%, rgba(71,30,72,1) 100%); */
	/* background: linear-gradient(156deg, rgba(4,13,33,1) 56%, rgba(71,30,72,1) 100%); */

	/* background:rgb(27, 31, 35); */
	/* background-color: #1c2128 !important; */
	background-color: #0d1117 !important;
	color: #c9d1d9 !important;
	height: 100%;
	position: absolute;
	left: 0;
	width: 100%;
	overflow: auto;
}

.center-cropped {
	width: 40px;
	height: 40px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 45px auto;
	border-radius: 30px;
	cursor: pointer;
}

/* ez most csak a sticky header */
.bg-dark {
	/* background-color: #272b33 !important; */
	background-color: #161b22 !important;
	
	/* background-color: #161b22; */
	/* border-color: rgb(208, 215, 222); */
	/* border-color: #938F99; */

	/* color: rgb(173, 186, 199); */
}

.color-blue {
	/* color: rgb(83, 155, 245) !important; */
	color: #58a6ff !important;
}

/* .text-white {
	color: #adbac7 !important;
} */
.bg-danger{
	color:#f0f3f6 !important;
}
.shadow-lg{
	box-shadow:0 8px 24px #010409 !important;
}

.nettoBadge {
	/* background-color: rgb(36, 41, 47) !important; */
	background-color: #2d333b !important;
}

/* header: */
/* #root > div.bg-darky.text-white.pb-5 > section { */
.indikativ{
	/* background-color: rgb(27, 31, 35) !important; */
	background-color: #0d1117 !important;
	color: #c9d1d9 !important;
}

.vegleges{
	background-color: rgb(22, 42, 70) !important;
	color:#f0f6fc!important;
}

/* #helysziniAdottsagok,
.card,
.accordion-item,
#root > .border-start,
.list-group-flush > .list-group-item {
	border-color: #373e47 !important;
	border-color: #444c56 !important;
	border-radius: 6px;
} */
.card-header{
	background-color: rgba(110,118,129,0.1) !important;
	border-color: #30363d !important;
	border-radius: 6px;
}

.card{
	box-shadow: 0 3px 6px #010409 !important;
}
.card-body, .card{
	background-color: #161b22 !important;
	border-color: #30363d !important;
}
.form-control:disabled,
.form-control[readonly] {
	/* background-color: #e9ecef; */
	opacity: 0.7;
	/* color: #6a6a6a; */
}

.form-select:disabled {
	opacity: 0.7;
}

#root > div.bg-darky.text-white.pb-5 > form > div:nth-child(4) > div > button {
	background: linear-gradient(180deg, rgba(52, 183, 89, 0.15) 0%, rgba(46, 164, 79, 0) 100%),
		#2ea44f !important;
}

#root > div.bg-darky.text-white.pb-5 > form > div:nth-child(4) > div > button:hover {
	background: linear-gradient(180deg, rgba(27, 139, 59, 0.671) 0%, rgba(26, 133, 56, 0.356) 100%),
		#197a35 !important;
}

.loadingScreen {
	height: 100%;
	width: 100%;
	position: fixed;
	background-color: #040d21;
	opacity: 0.8;
	z-index: 99;
}
.spinnerAjanlat {
	position: absolute;
	width: 4rem;
	height: 4rem;
	top: 50%;
	left: calc(50% - 4rem);
	margin: auto;
	text-align: center;
	z-index: 1000;
}

button:disabled,
input:disabled,
select:disabled {
	cursor: not-allowed;
	pointer-events: all !important;
}

.max-260 {
	max-width: 260px !important;
}

/* inverter csomagok ListGroup */
.list-group-item{
	background-color: #161b22 !important;
	color:#fff;
	border-color:#444746;
}

.accordion-item{
	border-color: #30363d !important;
	color: #c9d1d9;
	box-shadow: 0 3px 6px #010409 !important;
}
.accordion-button{
	background-color: #13233a !important;
	color:#c9d1d9;
	box-shadow: 0 1px 3px #010409 !important;
}
.accordion-button:not(.collapsed){
	color:#58a6ff !important;
	box-shadow: 0 1px 3px #010409 !important;
}
.accordion-body{
	background-color: #161b22 !important;
}
/*--------------------- Accordion button icon --------------------- */ 
.accordion-button.collapsed::after {
	background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-button:not(.collapsed)::after{
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%2371b7ff' class='bi bi-chevron-up' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z'/%3E%3C/svg%3E") !important;
}
.btn-success{
	background-color: #238636 !important;
}
.btn-success:hover{
	background-color: #2ea043 !important;
}

/* Google Sign-in button */
#googleSignInButtin{
	width:235px;
	height:56px;
	padding: 0;
	border:0;
	background: url('./img/signinAssets/btn_google_signin_dark_normal_web@2x.png') no-repeat transparent;
	background-size: cover;
}

#googleSignInButtin:disabled{
	background: url("./img/signinAssets/btn_google_signin_dark_disabled_web@2x.png") no-repeat transparent;
	background-size: cover;
}

#googleSignInButtin:hover{
	background: url("./img/signinAssets/btn_google_signin_dark_focus_web@2x.png") no-repeat scroll transparent;
	background-size: cover;
}
#googleSignInButtin:active{
	background: url("./img/signinAssets/btn_google_signin_dark_pressed_web@2x.png") no-repeat scroll transparent;
	background-size: cover;
}

body > div.fade.modal.show > div {
	max-width: 1000px !important;
}